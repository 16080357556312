.single-album:not(.pjax) .iron-audioplayer, .album-template-single-album-sidecover:not(.pjax) .iron-audioplayer, .iron_music_player{ 
	.player{
		height: 130px;

		.control{
			width:158px;
			margin:20px auto 0;
			position:relative;
		}
		.play{
			position:absolute;
			left:50%;
			transform: translate(-50%,0);
			width:26px;
			height:21.3px;
		}
		.wave{
			margin: 20px 0;
			/*margin-left:40px;
			margin-right:40px;*/
		}
	}
}


//Soundwave spectro display condition
.iron_music_player[data-skin="music"] .iron-audioplayer .wave > wave, .iron_music_player[data-skin="music"] .iron-audioplayer .player{
	transition: 0.4s ease-out;
}
.iron_music_player[data-skin="music"] .iron-audioplayer:not(.audio-playing) .wave > wave, .iron_music_player[data-skin="music"] .iron-audioplayer:not([data-soundwave="1"]) .wave > wave{
	height: 0 !important;
}
.iron_music_player[data-skin="music"] .iron-audioplayer:not(.audio-playing) .player, .iron_music_player[data-skin="music"] .iron-audioplayer:not([data-soundwave="1"]) .player{
	height: 60px;
}


.iron-audioplayer .spectro{
	opacity: 0;
}

#sidebar .iron-audioplayer{ 
	.spectro, .wave{
		display: none;
	}
}

.iron_widget_radio:not(.playlist_enabled):not(.sonaar_artist_player):not(.iron_podcast_player){
	.iron-audioplayer{
		.player{
			.spectro{
				width:100%;
			}
		}
	}
}	
.sonaar_artist_player .playerNowPlaying .metadata{
	line-height:1em;
}
.iron_podcast_player .play{
	transition-timing-function: cubic-bezier(.16,.28,.42,1.05);
	transition: all 300ms ease-in-out;
	&:hover{
		transform: scale(1.1);
 	}
}

.iron-audioplayer{

	@each $widthColumn in vc_col-sm-6, vc_col-sm-4, vc_col-sm-2, vc_col-sm-3, vc_col-md-6, vc_col-md-4, vc_col-md-2, vc_col-md-3, vc_col-lg-6, vc_col-lg-4, vc_col-lg-2, vc_col-lg-3, {
		.#{$widthColumn} & .vc_col-md-6 {
			width:100%;
		}
	}
	.sr_it-playlist-title{
		font-size:2em;

	}
	.control{
			fill: #000;
	}
	.play{
		border-color: #000;
	}
	.album{
		width:100%;
		.album-art{
			max-width:450px;
			margin:0px auto;
			background-size:cover;
			img{
				width:100%;
			}
			@media (max-width: 1440px){
				max-width:350px;
			}
			/*@media (max-width: 600px){
				max-width:100%;
			}*/
		}
		.single-album & .album-art{
			width:100%;
			margin:0px auto;
			//max-width:500px;
			img{
				width: 100%;
				height:auto;
			}

		}
	}

	.track-title, .album-title{
		text-align:center;
	}
	.track-title{
		margin-bottom:17px;
	}
	.album-title{
	}
	.player{
		width: 100%;
		position:relative;
		font-size:12px;

			&.hide{
				.currentTime,.wave,.totalTime{
					opacity:0!important;
				}
			}

		.progressLoading{
			background: rgba(0, 0, 0, 0.25);
			width: 0%;
	    	height: 1px;
	    	box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
	    	width: calc( 100% - 200px );
			position: absolute;
			opacity: 0;
			left: 120px;
			top: 35px;
			transition:opacity 3000ms ease;
		}

		.totalTime{
			opacity:0.6;
			position: absolute;
		    top: 26px;
		    right: 0px;
		}
		.currentTime{
			opacity:0.6;
			position: absolute;
		    top: 26px;
		}

		.spectro{
			width: calc(100% - -25px);
			height: 85px;
			padding: 0 0 10px;
			box-sizing: border-box;
			top: -10px;
			position: absolute;
		}

		.control{

			.play{
				opacity:1!important;
				display:block;
				cursor:pointer;

				path, rect{
					transition:all 300ms ease;
				}
				rect{
					opacity:0;
				}

			}
			.previous{
				position:absolute;
				left:0;
				top:8px;bottom:0;
				opacity:1!important;
				width:16px;
				height:21.3px;
				display:block;
				cursor:pointer;
			}
			.next{
				position:absolute;
				right:0;
				opacity:1!important;
				top:8px;bottom:0;
				width:16px;
				height:21.3px;
				display:block;
				cursor:pointer;
			}
		}
	}
	&.show-playlist .playlist{
		display:block;
	}
	&:not(.show-playlist) .sonaar-grid{
		display:block;	
	}
	.playlist{
		display:none;
		// margin-bottom:50px;
		a{
			&.button{
			opacity:1;
			text-transform: uppercase;
    		font-weight: 700;
			font-size:11px;
			padding:1px 6px;
			margin:0;
			border-radius:3px;
			}
		}

		ul{
			padding:0 0;
			margin:25px 0;
		}
		li{
			list-style:none;
			position:relative;
			clear:both;
			margin:10px 0;
			display: flex;
		    align-items: center;
		    flex-wrap: nowrap;
		    justify-content: space-between;
		    flex-direction: row-reverse;

			.button{
				float:right;
				width:auto;
				display:table-cell;

			}

		}
		.current{

				path{
					opacity: 0;
				}
				rect{
					opacity: 1;
				}
				.audio-track{
					font-weight:700;
				}

			}
		@media (max-width: 991px){
			margin-top:20px;
		}
	}
	.tracklist-item-title{
		display: inline-block;
		text-align:left;
	}
	.track-number{
		:before{
			content:" ";
			width: 0;
		}
		display:inline-block;
		font-size:16px;
		height:100%;
		padding-left: 25px;
		padding-right: 10px;
		position: relative;
		vertical-align: top;
		.number{
			display:inline-block;
			vertical-align: top;
		}
		svg{
			width:10px;
			height:12px;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
		}
		rect{
			opacity: 0;
		}
	}
	.song-store {
    	float: right;
    	margin-left: 10px;
	}
	.audio-track{
		display:inline-block;
		cursor:pointer;
		font-size:16px;
		color:#a67f57;
		height:100%;
	}

	&.show-playlist{
		@media (min-width: 992px){
			.buttons-block{
				padding-left: calc(50% - 336px);
			}
		}
		@media (min-width: 1441px){
			.buttons-block{
				padding-left: 213px;
			}
		}
	}

	&:not(.show-playlist){
		.available-now, .buttons-block{
			text-align: center;
		}
		.ctnButton-block{
			display: inline-block;
			.store-list{
				text-align: center;
			}
		}
	}
}

.iron_music_player{
	.iron-audioplayer:not(.show-playlist){
		.sr_it-playlist-release-date, .sr_it-playlist-title, .sr_it-playlist-artists{
			display:none;
		}
	}
}

.single-album .info-section h2{
	text-align:center;
}

.single-album .buttons-block{
	display:block;
	width:100%;
	margin-top: 2px;
    margin-bottom: 60px;
	.ctnButton-block{
		padding: 22px 20px;
		display: table;
    	margin: 0 auto;
	}
	.available-now, .store-list li{
		display:table-cell;
		vertical-align: middle;
		padding: 5px 15px 0 5px;
	}
	.available-now{
	    font-weight: 900;
	    font-size: 16px;
	    line-height: 20px;
	    text-align: center;
	    text-transform: uppercase;
	}
	.store-list{
		li{
			float: left;
			.button{
				padding: 4px 10px;
				border-radius: 3px;
				font-weight:700;
			}
		}
		.fa{
            font-size: 13px;
            width: 17px;
            text-align: left;
            margin-right: 5px;

        }
	}
}

.iron-audioplayer.audio-playing .player .control{
	.play{
		path{
			opacity:0;
		}
		rect{
			opacity:1;
			}
	}
}

.pjax.single-album .iron-audioplayer .player .control, .pjax.single-album .iron-audioplayer .player{
	display:none;
}


.continuousPlayer{
	position: fixed ;
	bottom: 0;
	transform: translate3d( 0, 95px, 0 );
	height:90px;
	width:100%;
	z-index:8010;
	transition: transform 0.75s ease;

	&.enable{
		bottom: 0;
		transform: translate3d( 0, 0, 0 );
	}

	.playerNowPlaying{
		.album-art{
			height: 70px;
			width: 70px;
			position: absolute;
			top: 10px;
			left: 10px;
			background:#222;

			.loading-icon{
				color: #fff;
			    position: absolute;
			    width: 100%;
			    top: 20px;
			    opacity: 0;
			    transition: opacity 0.75s ease;

			}
			img{
				width: 100%;
				height: auto;
			}
		}
		.metadata{
			position: absolute;
			left: 100px;
			top: 25px;
			letter-spacing: 1px;
			width: 200px;
    		overflow: visible;

			.album-title{
				font-size: 0.8em;
				line-height: 0.8em;
			}
		}
	}

	.player{
		width: calc( 100% - 300px );
		height: 100%;
		position: absolute;
		left: 300px;
		top: 0px;

		#cplayer{
			opacity:0;
			transition:all 3000ms ease;

			&.hide{
				opacity:0;
			}
			&.reveal{
				opacity:1;
			}
		}
		.progressLoading{
			background: rgba(255, 255, 255, 0.25);
			width: 0%;
	    	height: 1px;
	    	box-shadow: 0 0 2px rgba(255, 255, 255, 0.5);
	    	width: calc( 100% - 200px );
			position: absolute;
			left: 10px;
			top: 45px;
			transition:opacity 3000ms ease;
		}
		.wave{
			width: calc( 100% - 200px );
			position: absolute;
			left: 10px;
			top: 28px;

			wave{
				overflow-x: hidden!important;
			}
		}

		.control{
			width: 120px;
		    position: absolute;
		    right: 10px;
		    top: 0;
		    height: 90px;

			.play{
				position:absolute;
				left: 50%;
				transform: translate(-50%,0);
				height: 100%;
				width: 50px;
				opacity: 1!important;
				display: block;
				cursor: pointer;

				svg{
					height: 25px;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					left: 50%;
				}

				path, rect{
					transition:all 300ms ease;
				}
				rect{
					opacity:0;
				}

				.audio-playing{
					path{
						opacity:0;
					}
					rect{
						opacity:1;
						}
				}

			}
			.previous{
				position:absolute;
				left:0;
				bottom:0;
				top:0;
				opacity:1!important;
				width: 30px;
				height: 100%;
				display:block;
				cursor:pointer;

				svg{
					height: 15px;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					left: 50%;
				}
			}
			.next{
				position:absolute;
				right:0;
				opacity:1!important;
				bottom:0;
				top:0;
				width: 30px;
				height: 100%;
				display:block;
				cursor:pointer;

				svg{
					height: 15px;
					top: 50%;
					transform: translate(-50%, -50%);
					position: absolute;
					left: 50%;
				}
			}
		}
	}
}



.continuousPlayer.audio-playing .player .control{
	.play{
		path{
			opacity:0;
		}
		rect{
			opacity:1;
			}
	}
}
.continuousPlayer.loading .playerNowPlaying .album-art .loading-icon{
	opacity:1;
}

.sonaar_artist_player{
	@extend .continuousPlayer;
	position:absolute;
	top:-90px;
	transform:initial!important;
	height:180px;
	background:#777;


	@media screen and (max-width: 1440px) {
		height: 120px;
		top: -60px;

	}
	@media screen and (max-width: 767px) {
		height: 90px;
		top: -60px;
	}
	.artist-template-single-artists-blank &{
		top: -275px;

		@media screen and (max-width: 1440px) {
			top: -225px;
		}
		@media screen and (max-width: 767px) {
			top: -175px;
			height: 90px;
		}
	}

	.playerNowPlaying {
		position: relative;
		z-index: 200;
		.album-art{
			width:136px;
			height:136px;
			top:22px;
			left:22px;
			background:none;
			overflow: hidden;
				// img{
				// 	display: none;
				// }

			@media screen and (max-width: 1440px) {
				width: 100px;
				height: 100px;
				top:10px;
				left:10px;
			}
		}
		.metadata{
			font-size:23px;
			left:190px;
			top:45px;
			width:330px;
			.track-name,.album-title{
				display: inline-block;
				background-color: rgb(64, 174, 146);
				padding: 9px 5px 5px 0;
				text-align: left;
				float: left;
				clear: both;
			}

			@media screen and (max-width: 1440px) {
				left:135px;
				top:20px;
				width:330px;
			}
		}

		@media screen and (max-width: 767px) {
			position: absolute;
			left: 0;
			transform: scale(0.75) ;
		}
	}
	.player{
		width: calc( 100% - 190px);
		left: 190px;
		.spectro{
			width: calc(100% - 15px);
			height: 147px;
			padding: 0 0 10px;
			box-sizing: border-box;
			top: 22px;
			position: absolute;
		}

		.progressLoading{
			top:90px;
			width: calc(100% - 275px);
		}
		.control{
			height:180px;
			right:45px;
			z-index:1000;
		}
		.wave{
			width: calc(100% - 275px);
			top:72px;
		}

		@media screen and (max-width: 1440px) {
			width: calc( 100% - 120px );
			left:135px;

			.spectro{
				width: calc(100% - 20px);
				height:110px;
				top:10px;
			}
			.progressLoading{
				top:62px;
				width: calc(100% - 275px);
			}
			.control{
				height:120px;
				right:50px;
			}
			.wave{
				width: calc(100% - 275px);
				top:45px;
			}
		}
		@media screen and (max-width: 767px) {
			left: 0;
			width: 100%;
			.spectro{
				display:none;
			}
			.progressLoading{
				display: none;
			}
			#artistPlayer{
				display: none;
			}
			.control{
				right: 0;
				transform:scale(0.8);
				height: 95px;
			}
		}
	}
}

[data-audiopath = ""]{
	.track-number svg{
		display:none !important;
	}
	.audio-track{
		cursor: default;
	}
} 

.artist-template-default .iron-audioplayer.show-playlist .buttons-block{
	padding-left: 15px;
}

.audioplayer-size{
	max-width: 1000px;
	margin: 0 auto;
	@media (max-width: 1440px){
		max-width:840px;
	}
}

//widget music audioplayyer only---------------//
.iron_music_player[data-skin="music"] .iron-audioplayer{
	.album{
		margin: 0 auto;
	}
	&.audioplayer-size{
		max-width: 100%;
	}
	&.show-playlist{ 
		.playlist{
			@media only screen and (max-width: 991px){
				display: inline-block;
			}
			max-width:100%;
			width:100%;
		}
		@media only screen and (min-width: 992px){
			.sonaar-grid .album{
				padding: 0 40px 0 0;
			}
		}
	}
	@media only screen and (max-width: 991px){
		.album-art{
			display: inline-block;
		}
	}
	.sonaar-Artwort-box{
		justify-self: end;
		text-align: center;
	} 
}
.available-now{
	text-align: left;
}
.ctnButton-block{
	display: inline-block;
}

//Artwork disable
.sonaar-no-artwork{
	.sonaar-grid{
		justify-content:center;
	}

}

