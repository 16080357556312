.sr_it-grid{

    width: auto;
    max-width: 100%;
    margin: 0 auto;
    padding: 1px 0 0;
    opacity:0;
    text-align: center;

    &.columnactive{
        opacity:1;
    }
    &:after{
        content:'';
        clear: both;
        display: table;
        float: none;
    }


    .sr_it-column-2{
        margin: 0 auto;
        vertical-align: top;


        @media screen and (max-width: $screen_tablet){
                max-width: 360px;
        }
    }


    @media screen and (min-width: $screen_tablet){
        width: 700px;

        .sr_it-column-2{
            width: 320px;
            max-width: calc(50% - 20px);
            margin: 10px;
            display: inline-block;

        }

    }

    @media screen and (min-width: $screen_desktop){
        width: 860px;

        .sr_it-column-2{
            width: 410px;
        }

    }

    @media screen and (min-width: 1440px){
        width: 1114px;

        .sr_it-column-2{
            width: 507px;
            max-width: calc(50% - 100px);
            margin-left: 50px;
            margin-right: auto;


            &:first-child{
                text-align: right;
                text-align: -webkit-right;
                margin-left: auto;
                margin-right: 50px;

            }
        }



    }

    &.column-3{


        .sr_it-column-3{
            margin: 10px auto;
            vertical-align: top;

            @media screen and (max-width: $screen_tablet){
                    max-width: 360px;
            }
        }

        @media screen and (min-width: $screen_tablet){
            width: 700px;

            .sr_it-column-3{
                float:left;
                width: 212px;
                max-width: calc(33% - 20px);
                margin: 10px;
                display: inline-block;

            }

        }

        @media screen and (min-width: $screen_desktop){
            width: 1000px;

            .sr_it-column-3{
                width: 310px;
            }

        }
        @media screen and (min-width: 1480px){
            width: 1440px;

            .sr_it-column-3{
                width: 420px;
                max-width: calc(33% - 50px);
                margin:0 25px;
            }

        }
    }


    article{
        position: relative;
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 20px;
        text-align: right;

        @media screen and (min-width: 1440px){
            margin-bottom: 75px;
        }

        a{
            display: block;
            width: 100%;
            height: 100%;
        }
        img{
            width:100%;
            height: auto;
            display:block;
        }

        .sr_it-hoverable{
            background-color: rgba(0,0,0,0.6);
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            transition: opacity 400ms ease, box-shadow 400ms ease;
            box-shadow: 0 0 5px rgba(0,0,0,0.5);
        }

        header{
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 40px 25px;
            color: #fff;


        }
        .sr_it-item-title{
            font-size: 36px;
            font-weight: 700;
            margin-bottom: 10px;
            color: #fff;
        }
        .sr_it-meta{
            .entry-date{
                font-size: 18px;
                color: #fff;
                padding-bottom: 10px;
                border-bottom: 1px solid #fff;
                margin-bottom: 10px;
            }
        }
        .author{
            font-size: 21px;
            display: block;
            text-align: right;
            font-weight: 700;
        }
        button{
            background: transparent;
            border: 1px solid #fff;
            padding: 15px 20px;
            text-transform: uppercase;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            display: inline-block;
            margin-top: 40px;
            transition: box-shadow 400ms ease;
            box-shadow: 0 0 0 rgba(255,255,255,0.75);

            &:hover{
                box-shadow: 0 0 25px rgba(255,255,255,0.75);
                cursor: pointer;
            }
        }

        &.artist{
            overflow: hidden;
            button, .author, .sr_it-meta{
                display:none;
            }
            .sr_it-hoverable{
                opacity:1;
                background-color: transparent;
                background: rgba(0,0,0,0.6);
                background: linear-gradient(to bottom,rgba(0,0,0,0) 50%,rgba(0,0,0,0.83) 99%,rgba(0,0,0,0.85) 100%);
                transition: opacity 400ms ease, transform 800ms ease;
                &:hover{
                    transform: scaleY(1.2);
                    opacity: 0;
                }
            }

            header{
                width: calc( 100% - 50px);
                text-align: center;
                padding: 10px 25px;
            }
        }
        @media screen and (min-width: $screen_desktop){
            &:hover{
                .sr_it-hoverable{
                    opacity: 1;
                }
            }
        }
    }
}

#sidebar .sr_it-grid{
    .sr_it-hoverable{
        display: none;
    }
    &.column-3 .sr_it-column-3{
        max-width: calc(33% - 20px);
        margin: 0px 10px;
    }
    article{
        margin-bottom: 20px;
        img{
            transition: 0.2s ease;
        }
        a:hover img{
            filter: brightness(1.2);
        }
    }
}